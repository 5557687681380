.movie-form-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.movie-form-title-container h1 {
	margin-right: 25px;
}

.movie-form-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 40%;
	flex-direction: column;
}

.movie-form-container span {
	margin: 20px 20px;
	color: rgb(94, 94, 94);
}

button {
	background: none;
	border: 2px solid black;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.4s;
	margin: 0 25px;
}

button:hover {
	color: white;
	background-color: black;
}

.movie-form-confirm-button {
	background: none;
	border: 2px solid rgba(0, 132, 255, 0.658);
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	transition: 0.4s;
	margin: 0 25px;
	color: rgba(0, 132, 255, 0.658);
}

.movie-form-confirm-button:hover {
	color: white;
	background-color: rgba(0, 132, 255, 0.658);
	border-color: white;
}