.character-detail-container {
  border: 1px solid black;
  border-radius: 10px;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.418) 5px 5px 10px;
	transition: 0.3s;
	margin-bottom: 20px;
}

.character-detail-container:hover {
  transform: scale(1.05);
}

.character-detail-container > img {
  width: 350px;
  border-radius: 5px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2vh;
}