.movieList-item {
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: black 1px solid;
	border-radius: 10px;
	height: 400px;
	width: 20%;
	text-align: center;
	margin: 20px 0;
	box-shadow: black 3px 5px 10px;
	transition: 0.6s;
	overflow: hidden;
}

.movieList-item:hover {
	transform: scale(1.03);
}

.movieList-item-img {
	height: 60%;
	border-radius: 20px;
}